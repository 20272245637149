import { useState } from 'react';
import { useRouteError } from "react-router-dom";
import { Typography } from '@mui/material';
import { Stack } from '@mui/material';

export default function ErrorPage() {
    const error = useRouteError();
    console.error(error);

    return (
        <div id="error-page" style={{width: '100vw', height: '100vh'}}>
            <div id="error-page" style={{margin:'auto'}}>
                <h1>To Err is Human; To Handle Exceptions, Divine</h1>
                <p>Sorry, something seems to have gone wrong.</p>
                <p><i>{ error.statusText || error.message }</i></p>
            </div>
        </div>
    )
}