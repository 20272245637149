import { useState } from 'react';
import Box from '@mui/material/Box';
import { Divider, Typography } from '@mui/material';
import { Stack } from '@mui/material';
// import PortfolioCard from '../components/ProjectDisplay';
// import Content from '../data/projects.js';
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { styled } from '@mui/material';
import { Outlet, useLoaderData, useRouteLoaderData } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { getCategories } from '../data/projects';
import Navigation from '../components/Navigation';

// const categories = Content.map(x => x.category)

const theme = responsiveFontSizes(createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: 'hsl(40, 80%, 60%)',
    },
    secondary: {
      main: 'hsl(240, 15%, 40%)',
    }
  },


}))

export function loader() {
  const categories = getCategories();
  return categories;
}

// window.addEventListener('scroll', () => {
//   document.body.style.setProperty('--scroll',window.pageYOffset / (document.body.offsetHeight - window.innerHeight));
// }, false);


export function Index() {
  return (
      <Typography sx={{
          display: 'flex', 
          justifyContent: 'center',
          alignItems: 'center',
          height: '50%',
          opacity: 0.6,
      }} variant="h3"><i>Please select a category</i></Typography>
  )
}

function App() {
  // const [category, setCategory] = useState('apps')
  const [project, setProject] = useState('sippycup')
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down('xs'))
  const categories = useLoaderData()
  const projects = useRouteLoaderData('category')

  console.log(theme.breakpoints.down('sm'))

  return (
    <>
      
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div style={{width: '100%', position: 'fixed', minHeight: '100vh'}} id="background">
          <div style={{width: '100vw', height: '100vh', background: 'linear-gradient(hsla(240, 100%, 10%, 1), rgba(58, 14, 43, 0.75), rgba(238, 133, 47, 0.12), rgba(194, 133, 10, 0.07))', position: 'relative', opacity: 0.5}}></div>
          <div style={{display: 'flex', justifyContent: 'center' }}>
            <div style={{width: '125vh', height: '100vh', position: 'absolute', top: 0, backgroundImage: 'url("/assets/bg.jpg")', backgroundSize: 'cover'}}></div>
            <div style={{width: '125vh', height: '100vh', position: 'absolute', top: 0, background: 'linear-gradient(rgba(4, 4, 22, 0.85) 5%, rgba(18, 8, 11, 0.65) 65%, transparent 95%)' }}></div>
          </div>
        </div>
        
        
        
          <div style={{maxWidth: '115vh', height: '100vh', margin: 'auto', position: 'relative'}}>
          
            
            <Box pt={2}>

              { isSmallScreen ? 
                <Box mx={2}>
                  <Typography variant="h2" sx={{color: 'hsl(40, 80%, 60%)'}}>TravisTaylor.dev/</Typography>
                  <Typography variant="h2" sx={{color: 'hsl(240, 15%, 40%)'}}>portfolio</Typography>
                </Box>
              :
                <Stack direction="row" sx={{justifyContent: 'center'}}>
                  <Typography variant="h2" sx={{color: 'hsl(40, 80%, 60%)'}}>TravisTaylor.dev/</Typography>
                  <Typography variant="h2" sx={{color: 'hsl(240, 15%, 40%)'}}>portfolio</Typography>
                </Stack>
              }

            <Divider />

              
            </Box>

            { projects ? 
            <Outlet />
            :<><Stack direction = {isSmallScreen ? "row" : "column" } mt={1}>
                <Navigation isSmallScreen = { isSmallScreen } isExtraSmallScreen={isExtraSmallScreen} content={categories} />
            </Stack>
            <Outlet />
            </>
            }

            


            
            

            {/* <PortfolioCard 
                    title='Sippycup'
                    shortDescription='in-browser Flask sandbox'
                    imageUrl='./sippycup.jpg'
                    skills={["React.js", "Python", "Flask", "Pyodide"]}
                    blurb='Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim, pariatur. Excepturi reiciendis est corrupti reprehenderit, soluta ad eveniet eum! Tempore, dolor voluptatibus? Quas sunt quod, consectetur vero quia quam eum.'
                    url={'https://sippycup.app'}
                    github='https://github.com/travisdoesmath/sippycup'
                  /> */}
            
            {/* <div style={{width: '10000px', overflow: 'hidden'}}>
              { Content.map(category => <Grid container spacing={2}>
                {category.examples.map(example => <Grid key={example.name} item>
                  
                </Grid>)}
              </Grid>)}
            </div> */}

            
          </div>
        
      </ThemeProvider>
    </>      
  );
}

export default App;
