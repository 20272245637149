import { Box, Divider, Stack, Typography, useMediaQuery } from "@mui/material";
import { Outlet, useRouteLoaderData } from "react-router-dom";
import { useLoaderData } from 'react-router-dom';
import Navigation from '../components/Navigation';
import { getProjects } from "../data/projects";
import SubdirectoryArrowRight from "@mui/icons-material/SubdirectoryArrowRight";

export function loader({ params} ) {
    return getProjects(params.categoryId)
}

export function Index() {
    return (
        <Typography sx={{
            display: 'flex', 
            justifyContent: 'center',
            alignItems: 'center',
            height: '50%',
            opacity: 0.6,
        }} variant="h3"><i>Please select a project</i></Typography>
    )
  }

export default function Category(props) {
    const categories = useRouteLoaderData('portfolio')
    const projects = useLoaderData()
    const isSmallScreen = useMediaQuery('(max-width:750px)')
    const isExtraSmallScreen = useMediaQuery('(max-width:600px)')

    return (
        <>
            <Stack direction = {isExtraSmallScreen ? "row" : "column" } mt={1}>
                <Navigation isSmallScreen={isSmallScreen} isExtraSmallScreen={isExtraSmallScreen} prefix='..' content={categories} />
                <Navigation isSmallScreen={isSmallScreen} isExtraSmallScreen={isExtraSmallScreen} variant="h6" content={projects} />
            </Stack>
            <Divider />
            <Outlet />
        </>
    )    
}