import { Stack, Typography, Link, Grid, Divider } from '@mui/material'
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
// import NavLink from '../components/NavLink';
import { NavLink, useNavigation } from 'react-router-dom';




export default function Navigation(props) {
    const navigation = useNavigation();

    if (props.content) {
        const links = props.content.map(x =>
            <Link component={NavLink}
                mx={2}
                key={x.id} 
                sx={{
                    'color': 'hsl(240, 15%, 40%)',
                    '&.active': {
                        color: 'hsl(40, 80%, 60%)'
                    }
                }}
                to={props.prefix ? `./${props.prefix}/${x.id}` : `./${x.id}`} 
                underline='hover' 
                >
            <Typography variant={ props.variant ? props.variant : "h5" }>{ (props.isSmallScreen && x.shortName) ? x.shortName : x.name }</Typography> 
        </Link>)
        
        return <Stack direction={ props.isExtraSmallScreen ? "column" : 'row' } sx={{justifyContent: 'center'}}>{ links }</Stack>
    } else {
        return <></>
    }

}