import { Avatar, Card, CardHeader, Chip, Typography, CardContent, CardMedia, CardActionArea, CardActions, Button, Link, Breadcrumbs, Stack, Divider, useMediaQuery } from '@mui/material';
import GitHubIcon from '@mui/icons-material/GitHub';

export default function ProjectDisplay(props) {
    const isSmallScreen = useMediaQuery('(max-width:700px)')
    
    return (
        <>
            { isSmallScreen 
                ? 
                
                    
                    <Stack spacing={2} mt={2} sx={{maxWidth: '90vw', marginLeft: 'auto', marginRight: 'auto'}}>
                        <Stack direction="row" spacing={2} sx={{alignItems: 'center'}}>
                            <div style={{
                                backgroundImage: `url(${props.imageUrl})`,
                                width: '70px',
                                minWidth: '70px',
                                height: '70px',
                                borderRadius: '15px',
                                boxShadow: 'rgba(0, 0, 0, 0.5) 5px 5px 15px',
                                backgroundSize: 'cover'
                            }}></div>
                            <Stack>
                                <Typography variant="h4">{props.title}</Typography>
                                <Typography >{props.shortDescription}</Typography>
                            </Stack>
                        </Stack>
                        
                        
                        <Stack direction="row" spacing={1} >{ props.skills.map(skill => (<Chip key={ skill } label={ skill } />))}</Stack>
                        <Card sx={{backgroundColor: '#000A'}}>
                            <CardContent>
                                {props.blurb}
                            </CardContent>
                        </Card>
                        <Stack spacing={2} direction="row">
                            <Button color="secondary" variant="contained" href={ props.url }>Link</Button>
                            {props.github ? <Button color="secondary" variant="contained" href={ props.github }><GitHubIcon />&nbsp;Github</Button> : ''}
                        </Stack>
                    </Stack>
                

                :
                <Stack direction="row" sx={{justifyContent:'center'}}>
                <div style={{
                    backgroundImage: `url(${props.imageUrl})`,
                    width: '350px',
                    minWidth: '350px',
                    height: '350px',
                    borderRadius: '15px',
                    boxShadow: 'rgba(0, 0, 0, 0.5) 5px 5px 15px',
                    marginTop: '16px',
                    backgroundSize: 'cover'
                }}></div>
                <Stack>
                    <div style={{width: '350px', marginLeft:'32px', marginTop:'16px'}}>
                    <Typography variant="h3">{props.title}</Typography>
                    <Typography mt={1}>{props.shortDescription}</Typography>
                    <Stack direction="row" spacing={1} m={1} >{ props.skills.map(skill => (<Chip key={ skill } label={ skill } />))}</Stack>
                    {props.blurb}
                    <Stack spacing={2} direction="row">
                        <Button color="secondary" variant="contained" href={ props.url }>Link</Button>
                        {props.github ? <Button color="secondary" variant="contained" href={ props.github }><GitHubIcon />&nbsp;Github</Button> : ''}
                    </Stack>
                    </div>
                    
                </Stack>
            </Stack>

            }
        </>
    )
}

