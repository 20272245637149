import { getProject } from "../data/projects"
import ProjectDisplay from "../components/ProjectDisplay"
import { useLoaderData } from "react-router-dom"

export function loader({ params }) {
    return getProject(params.projectId)
}

export default function Project(props) {
    const project = useLoaderData()

    return <ProjectDisplay
        title = { project.name }
        shortDescription = { project.shortDescription }
        imageUrl = { project.imageUrl }
        skills = { project.skills }
        blurb = { project.blurb }
        url = { project.url }
        github = { project.github }
        
    />

}