import { Link } from "@mui/material"

const categories = [
    {
        id: 'apps',
        name: 'Apps',
        shortName: "Apps",
        blurb: 'From quick applets to full-stack applications',
    },
    {
        id: 'visualizations',
        name: 'Visualizations',
        shortName: "Viz",
        blurb: 'Interactive data visualizations',
    },
    {
        id: 'tutorials-and-articles',
        name: 'Tutorials & Articles',
        shortName: "Tutorials",
        blurb: 'Sometimes I write things',
    },
    {
        id: 'mathematical-explorations',
        name: 'Mathematical Explorations',
        shortName: "Math",
        blurb: 'Exploring and visualizing mathematical concepts and figures',
    }
]

const projects = [
    {
        name: 'Sippycup',
        id: 'sippycup',
        category: 'apps',
        shortDescription: "in-browser Flask sandbox",
        skills: ["React.js", "Python", "Flask", "Pyodide"],
        blurb: <><p>Built on top of <Link href="https://pyodide.org/en/stable/">Pyodide</Link>, Sippycup is a mock-up of a Flask environment that runs entirely within the browser.</p>
        <p>Aimed at new learners of Flask, users can experiment, test, and share code without even having Python installed; it even runs on mobile devices!</p></>,
        url: 'https://sippycup.app',
        github: 'https://github.com/travisdoesmath/sippycup',
        imageUrl: '/assets/sippycup.jpg'
    },
    {
        name: 'Umbly Bumbly',
        id: 'umbly-bumbly',
        category: 'apps',
        shortDescription: "casual mobile game",
        skills: ["JavaScript"],
        blurb: <><p>Umbly Bumbly is a low-pressure, one button, casual game where you play as a bumblebee collecting pollen from flowers, and avoiding thistles.</p></>,
        url: 'https://umblybumbly.com',
        imageUrl: '/assets/umblybumbly.jpg'
    },
    {
        name: 'Circle of Modes',
        id: 'circle-of-modes',
        category: 'apps',
        shortDescription: "musical scales and modes explorer",
        skills: ["JavaScript", "D3.js"],
        blurb: <><p>Circle of Modes is an interactive extended circle of fifths that explores <Link href="https://en.wikipedia.org/wiki/Mode_(music)">modes</Link> of the major scale
        and their relations to each other.</p></>,
        url: 'https://travisdoesmath.github.io/circle-of-modes/',
        github: 'https://github.com/travisdoesmath/circle-of-modes',
        imageUrl: '/assets/modes.png'
    },
    {
        name: 'Milky Way Planner',
        id: 'milky-way-planner',
        category: 'apps',
        shortDescription: "galactic center visibility assistant",
        skills: ["JavaScript", "D3.js"],
        blurb: <><p>A planning tool for wide-field astrophotography, showing the rise, set, and altitude of the galactic center and Moon for a given date and location, as well as start and end times of daylight and various twilights.</p>
        <p>This tool was used to plan the photo used as the background for this site.</p></>,
        url: 'https://travisdoesmath.github.io/milky-way-planner/',
        github: 'https://github.com/travisdoesmath/milky-way-planner',
        imageUrl: '/assets/milkywayplanner.png'
    },
    {
        name: 'Covid Dashboard',
        id: 'covid-dashboard',
        category: 'visualizations',
        shortDescription: 'visualizing impact over time of COVID-19',
        skills: ["D3.js"],
        blurb: <><p>Using data from the CDC and the COVID Tracking Project on COVID deaths in the U.S., this dashboard showed daily updates until March 7, 2021, using custom graphs and my own personal redesign of a state tile map.</p>
        </>,
        url: 'https://travisdoesmath.github.io/covid-viz/',
        github: 'https://github.com/travisdoesmath/covid-viz',
        imageUrl: '/assets/covid.png'
    },
    {
        name: 'CTA Ridership',
        id: 'cta-datatable',
        category: 'visualizations',
        shortDescription: 'Datatable of Chicago train ridership',
        skills: ["D3.js"],
        blurb: <><p>A customized datatable visualizing daily ridership distribution of Chicago trains by CTA station.</p><p>Notable elements include a sparkline-esque box plot and 4-way sortable location column.</p></>,
        url: 'https://travisdoesmath.github.io/cta-ridership',
        github: 'https://github.com/travisdoesmath/cta-ridership',
        imageUrl: '/assets/cta-ridership.png'
    },
    {
        name: "Du Bois Spiral",
        id: "du-bois-spiral",
        category: 'visualizations',
        shortDescription: 'recreation of a visualization by W.E.B. Du Bois',
        skills: ["D3.js"],
        blurb: <><p>Inspired by a unique and visually compelling data visualization that W.E.B. Du Bois hand painted in 1890, this is a dynamic recreation in D3.js</p></>,
        url: 'https://travisdoesmath.github.io/d3-du-bois-spiral/',
        github: 'https://github.com/travisdoesmath/d3-du-bois-spiral',
        imageUrl: '/assets/du-bois-spiral.png'

    },
    {
        name: "Comfy Weather",
        id: "comfy-weather",
        category: "visualizations",
        shortDescription: "exploring U.S. cities by weather (and affordability)",
        skills: ["JavaScript", "D3.js", "HTML", "CSS"],
        blurb: <><p>After a particularly sudden cold-snap in Chicago, I questioned why I still lived there when my job at the time was 100% remote.</p>
        <p>I created this visualization to find new potential cities to live in based on the number of extreme temperature days per year, average income, annual inches of rain, and average daily sunlight.</p>
        <p>Eventually, I moved to Austin, TX.</p></>,
        url: "https://travisdoesmath.github.io/comfy-weather/",
        github: "https://github.com/travisdoesmath/comfy-weather",
        imageUrl: "/assets/comfy-weather.png"
    },
    {
        name: 'Code an n-tuple Pendulum Simulator',
        shortName: 'Pendulum Simulator',
        id: 'pendulum-explainer',
        category: 'tutorials-and-articles',
        shortDescription: "how-to guide on coding a chaotic simulator",
        skills: ["JavaScript", "HTML", "CSS"],
        blurb: <><p>Inspired by the blogs that helped me with the math to code a double pendulum simulator, this write-up dives into the math to model an arbitrary <i>n</i>-tuple pendulum (i.e. double, triple, quadruple, etc.),
        and then goes step by step into how to write JavaScript code to represent the mathematical model, code a Runge-Kutta solver to approximate solutions, and finally animate the simulation
        </p></>,
        url: 'https://sippycup.app',
        github: 'https://github.com/travisdoesmath/sippycup',
        imageUrl: '/assets/pendulum-explainer.png'
    },
    {
        name: "Code Golfing in Python",
        name: "Code Golf",
        id: "code-golf",
        category: "tutorials-and-articles",
        shortDescription: "having fun with terrible code",
        skills: ["Python"],
        blurb: <><p>An introduction to code golfing, a completely pointless excursion to writing short, incomprehensible code, in complete violation of Pythonic best practices."</p></>,
        url: "https://medium.com/swlh/a-pointless-excursion-code-golfing-in-python-e2104e2040ef",
        imageUrl: "/assets/code-golf.jpg"
    },
    {
        name: "Chaotic Pendulums",
        id: "chaotic-pendulums",
        category: "mathematical-explorations",
        shortDescription: "simulating chaos",
        skills: ["JavaScript", "D3.js"],
        blurb: <><p>Double pendulums are an illuminating example in dynamical systems, as the rules that govern the system are relatively simple, and yet produce rich, chaotic behavior</p>
        <p>Here, I explore interactive visualizations that show the sensitive dependence to initial conditions, as well as extending to triple pendulums and generalized n-tuple pendulums.</p></>,
        url: 'https://travisdoesmath.github.io/chaotic-pendulums',
        imageUrl: '/assets/chaotic-pendulum.png'
    },
    {
        name: "Mandelbrot & Julia",
        id: "mandelbrot-and-julia",
        category: "mathematical-explorations",
        shortDescription: "fractal connections",
        skills: ["JavaScript"],
        blurb: <><p>The Mandelbrot set is a famous fractal that can be considered as a "catalog" of Julia sets.</p>
        <p>In this visualization, as the cursor moves over the Mandelbrot set, the associated Julia set of that point is rendered on the left. The cursor can be left to move automatically, or follow the users input.</p></>,
        url: 'https://travisdoesmath.github.io/mandelbrot_julia_explorer/',
        imageUrl: '/assets/mandelbrot-julia.png'
    },
    {
        name: "Random Life",
        id: "random-life",
        category: "mathematical-explorations",
        shortDescription: "expanding on Conway's Game of Life",
        skills: ["JavaScript", "D3.js"],
        blurb: <><p>Conway's Game of Life is a famous zero-player game, or cellular automota, where simple rules give rise to complex behaviors.</p>
        <p>In this visualization, two approaches to adding randomness to Conway's original rules are explored</p></>,
        url: 'https://travisdoesmath.github.io/random-life/',
        imageUrl: '/assets/random-life.png'
    },


]


export function getCategories() {
    return categories
}

export function getProjects(categoryId) {
    return projects.filter(p => p.category === categoryId)
}

export function getProject(projectId) {
    const project = projects.filter(p => p.id === projectId)
    if (project.length > 0) return project[0]
    else console.error("Project not found")

}