import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import ErrorPage from './error-page';
import Portfolio, { Index as PortfolioIndex, loader as portfolioLoader} from './routes/Portfolio';
import Category, { Index as CategoryIndex, loader as projectsLoader } from './routes/Category';
import Project, { loader as projectLoader } from './routes/Project';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Navigate to="/portfolio" replace />
  },
  {
    path: "/portfolio",
    id: 'portfolio',
    element: <Portfolio />,
    errorElement: <ErrorPage />,
    loader: portfolioLoader,
    children: [
      { index: true, element: <PortfolioIndex />},
      {
        path: "/portfolio/:categoryId",
        id: 'category',
        element: <Category />,
        errorElement: <ErrorPage />,
        loader: projectsLoader,
        children: [
          { index: true, element: <CategoryIndex />},
          {
            path: "/portfolio/:categoryId/:projectId",
            id: 'project',
            element: <Project />,
            loader: projectLoader,
          }
        ]
      }
    ]
  }
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
